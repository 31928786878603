.App {
  background: #f0eff3;
  height: 100%;
}

.privacyContainer {
  max-width: 25rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 0.5rem;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 2rem;
}

.root {
  margin: auto;
  width: 90%;
}

.cardContentStyle {
  margin: 0;
  padding: 15px;
}
.VideoStyle{
  width: -webkit-fill-available;
  height: 55vh;
  border-radius: 1rem;
  overflow: hidden;
}
.cardHeadertStyle {
  padding-bottom: 0px;
}

.card {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  max-width: 35rem;
  border-radius: 1rem;
  margin: auto;
  margin-top: 20px;
  padding: 4px;
  background: white;
}

.downloadContainer {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  max-width: 23rem;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  border-radius: 0.7rem;
  background: white;
  padding-left: 20px;
}
/* On mouse-over, add a deeper shadow */
/* .card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
} */

.btn {
  display: inline-block;
  vertical-align: middle;
  background: rgb(24, 23, 23);
  padding: 1px;
  border-radius: 7px;
  height: fit-content;
  display: flex;
  justify-content: space-evenly;
  border-width: 0ch;
  align-items: center;
  margin-left: 10px;
}

.btn:hover {
  background: darkslateblue;
}
.img {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 17px;
  width: 17px;
}

.download {
  color: #534b63;
  line-height: '1.5rem';
  font-size: 16px;
  text-align: start;
  width: 100px;
}

.privacy {
  color: #534b63;
  line-height: '1.5rem';
  font-size: 15px;
}

.link {
  color: #534b63;
  text-decoration: none;
}

.btnText {
  color: white;
  margin-right: 15px;
  margin-left: 5px;
  font-size: 15px;
}

@media screen and (max-width: 800px) {
  .download {
    width: 100%;
  }
  .downloadContainer {
    padding-bottom: 20px;
    width: 80%;
  }
  .logo {
    height: 1.5rem;
  }
  .card {
    border-radius: 1rem;
    padding: 0;
    margin-top: 20px;
  }
  .root {
    width: 99%;
  }
  .privacyContainer {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .btnText {
    color: white;
    margin-right: 10px;
    margin-left: 5px;
    font-size: 10px;
  }
  .VideoStyle{
    height: 55vh;
  }

.img {
  margin-left: 10px;
  height: 14px;
  width: 14px;
}
  .btn {
    margin-left: 0px;
    margin-right: 10px;
    padding: 0px;
  }
}
